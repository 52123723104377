ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.selectWrapper {
  position: relative;
}
.selectWrapper .inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  padding: 0 10px;
  padding-right: 34px;
  border-radius: 4px;
  border: 1px solid;
  border-color: #1F1F1F;
  background-color: #1F1F1F;
}
.selectWrapper .inputWrapper .myInput {
  background-color: #1F1F1F;
  cursor: pointer;
  width: 2px;
  border: 0px;
  outline: 0px;
  padding: 0px;
  color: inherit;
  margin: 2px;
  font-size: inherit;
}
.selectWrapper .inputWrapper .fakeInput {
  position: absolute;
  top: 0px;
  left: 0px;
  visibility: hidden;
  font-size: inherit;
  height: 0;
}
.selectWrapper .inputWrapper .selectValue, .selectWrapper .inputWrapper .placeholder {
  color: #E2E2E2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 3px;
  margin-right: 3px;
  font-size: 0.8rem;
} 

.selectWrapper .inputWrapper .placeholder {
  color: #707070;
}

.selectWrapper .inputWrapper .clearAllIcon, .selectWrapper .inputWrapper .dropdownIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.selectWrapper .inputWrapper .clearAllIcon {
  right: 40px;
  fill: #c0c4cc;
}
.selectWrapper .inputWrapper .clearAllIcon:hover {
  fill: #999;
}
.selectWrapper .inputWrapper .dropdownIcon {
  fill: #E2E2E2;
  right: 10px;
  transition: all 0.3s;
}
.selectWrapper .inputWrapper .dropdownIcon.active {
  transform: translateY(-50%) rotate(180deg);
}
.selectWrapper .selectListWrapper {
  overflow: auto;
  position: absolute;
  top: 100%;
  border: 1px solid #1F1F1F;
  border-radius: 4px;
  background-color: #1F1F1F;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0);
  width: 100%;
  box-sizing: border-box;
  margin: 5px 0;
  z-index: 1000;
}
.selectWrapper .selectListWrapper .selectList {
  padding: 6px 0;
}
.selectWrapper .selectListWrapper .selectList .selectListItem, .selectWrapper .selectListWrapper .selectList .noItem, .selectWrapper .selectListWrapper .selectList .loadingItem {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
}
.selectWrapper .selectListWrapper .selectList .selectListItem {
  color: #E2E2E2;
  cursor: pointer;
  font-size: 0.8rem;
}
.selectWrapper .selectListWrapper .selectList .noItem, .selectWrapper .selectListWrapper .selectList .loadingItem {
  color: #E2E2E2;
  text-align: center;
  cursor: default;
}
.selectWrapper .selectListWrapper .selectList .loadingItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectWrapper .selectListWrapper .selectList .loadingItem .loadingIcon {
  padding-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectWrapper .selectListWrapper .selectList .loadingItem .loadingIcon svg {
  fill: #808080;
  animation: loading 1.5s infinite linear;
}
.selectWrapper .selectListWrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}
.selectWrapper .selectListWrapper::-webkit-scrollbar-track {
  background-color: transparent;
}
.selectWrapper .selectListWrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(144, 147, 153, 0.3);
}
.selectWrapper .selectListWrapper::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.5);
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
 }
  100% {
    transform: rotate(360deg);
 }
}