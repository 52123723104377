/* CSSTransition classNames for animation */
.modal-enter {
  transform: scale(0.9);
  opacity: 0;
}

.modal-enter-active {
  transform: scale(1);
  opacity: 1;
}

.modal-exit {
  transform: scale(1);
  opacity: 1;
}

.modal-exit-active {
  transform: scale(0.9);
  opacity: 0;
}

.modal-bottom-enter {
  opacity: 0;
  transform: translateY(100%);
}

.modal-bottom-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.modal-bottom-exit {
  opacity: 1;
  transform: translateY(0);
}

.modal-bottom-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 300ms, transform 300ms;
}